import cookies from 'js-cookie';
import { isEmpty } from 'lodash';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { fetchJSON } from '../../helpers/api';
import config from '../../helpers/config';

import { LOGIN_USER, LOGOUT_USER, REGISTER_USER, FORGET_PASSWORD } from './constants';
import {
  loginUserSuccess,
  loginUserFailed,
  registerUserSuccess,
  registerUserFailed,
  forgetPasswordSuccess,
  forgetPasswordFailed,
} from './actions';
import { getProfileSuccess } from '../profile/actions';

const setSession = (user) => {
  if (isEmpty(user)) {
    cookies.remove('user', { path: '/' });
  } else {
    cookies.set('user', JSON.stringify(user), { path: '/' });
  }
};

// type LoginAction = {
//   payload: {
//     username: string;
//     password: string;
//   };
//   type: typeof LOGIN_USER;
// };

function* login(action) {
  const { username, password } = action.payload;
  const options = {
    body: JSON.stringify({ username, password }),
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  };
  try {
    const response = yield call(fetchJSON, `${config.apiUrl}/login`, options);
    const data = response.data;
    yield put(getProfileSuccess(data));
    const user = {
      id: data.id,
      nip: data.nip,
      name: data.name,
      role: data.role.name,
      token: data.token,
    };
    setSession(user);
    yield put(loginUserSuccess(response));
  } catch (error) {
    yield put(loginUserFailed(error));
    setSession({});
  }
}

// type LogoutAction = {
//   type: typeof LOGOUT_USER;
//   payload: {
//     history: any;
//   };
// };

function* logout(action) {
  const { history } = action.payload;
  try {
    setSession({});
    yield call(() => {
      history.push('/account/login');
    });
  } catch (error) {}
}

// type RegisterAction = {
//   type: typeof REGISTER_USER;
//   payload: {
//     fullname: string;
//     email: string;
//     password: string;
//   };
// };

function* register(action) {
  const { fullname, email, password } = action.payload;
  const options = {
    body: JSON.stringify({ fullname, email, password }),
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  };

  try {
    const response = yield call(fetchJSON, '/users/register', options);
    yield put(registerUserSuccess(response));
  } catch (error) {
    let message;
    switch (error.status) {
      case 500:
        message = 'Internal Server Error';
        break;
      case 401:
        message = 'Invalid credentials';
        break;
      default:
        message = error;
    }
    yield put(registerUserFailed(message));
  }
}

// type ForgetPasswordAction = {
//   type: typeof FORGET_PASSWORD;
//   payload: {
//     username: string;
//   };
// };

function* forgetPassword(action) {
  const { username } = action.payload;
  const options = {
    body: JSON.stringify({ username }),
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  };

  try {
    const response = yield call(fetchJSON, '/users/password-reset', options);
    yield put(forgetPasswordSuccess(response.message));
  } catch (error) {
    let message;
    switch (error.status) {
      case 500:
        message = 'Internal Server Error';
        break;
      case 401:
        message = 'Invalid credentials';
        break;
      default:
        message = error;
    }
    yield put(forgetPasswordFailed(message));
  }
}

export function* watchLoginUser() {
  yield takeEvery(LOGIN_USER, login);
}

export function* watchLogoutUser() {
  yield takeEvery(LOGOUT_USER, logout);
}

export function* watchRegisterUser() {
  yield takeEvery(REGISTER_USER, register);
}

export function* watchForgetPassword() {
  yield takeEvery(FORGET_PASSWORD, forgetPassword);
}

function* authSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchLogoutUser),
    fork(watchRegisterUser),
    fork(watchForgetPassword),
  ]);
}

export default authSaga;
