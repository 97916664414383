export const fetchJSON = (url: string, options: RequestInit = {}) => {
  return fetch(url, options)
    .then((response) => {
      if (response.status !== 200) {
        throw response.json();
      }
      return response.json();
    })
    .then((json) => {
      return json;
    })
    .catch(async (error) => {
      const json = await error;
      throw json;
    });
};
