import React from 'react';
import Routes from './routes/Routes';
import { I18nProvider, LOCALES } from './helpers/i18n';

const App = () => (
  <I18nProvider locale={LOCALES.INDONESIA}>
    <Routes />
  </I18nProvider>
);

export default App;