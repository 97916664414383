export default {
  companyName: 'PT Suryacipta Swadaya',
  companyPhone: '+62267440088',
  companyPhone2: '+62 267 440088 / +62 267 440070',
  companyAddress:
    'The Manor Office, Suryacipta City of Industry, Jl Surya Utama Kav. C-1, Karawang, West Java, 41363, Indonesia',
  companyMail: 'info@smartpolitan.com',
  companySite: 'www.smartpolitan.com',
  companyGmap:
    'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7930.313524092021!2d107.327584!3d-6.3737538!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e6975d023f681ed%3A0x1e626e3653284e91!2sThe%20Manor!5e0!3m2!1sid!2sid!4v1602131656837!5m2!1sid!2sid',
  companyWebsite: 'https://www.suryainternusa.com',
  copyright: '2020',
  baseUrl: 'https://smartpolitan.com',
  // baseUrl: 'https://ssi.stagingapps.net',
  apiUrl: process.env.REACT_APP_API_BASE_URL || 'https://api.smartpolitan.com',
  // apiUrl: process.env.REACT_APP_API_BASE_URL || 'https://ssi-api.stagingapps.net',
  secureLSSecret: process.env.REACT_APP_SECURE_LS_SECRET || 'P@ssw0rd',
  captchaSiteKey: process.env.CAPTCHA_SITE_KEY || '6LfqQ-EZAAAAAOBzecUTVY2oV3QKx2L_k1pEVX29',
  googlePlayLink: 'https://play.google.com/store/apps/details?id=com.suryainternusa.android',
  appStoreLink: 'https://apps.apple.com/id/app/id1537368271',
  facebookLink: 'https://www.facebook.com/n/?100055165149492',
  twitterLink: 'https://twitter.com/smartpolitan',
  instagramLink: 'https://www.instagram.com/smartpolitan/',
  linkedInLink: 'https://www.linkedin.com/company/subang-smartpolitan',
  waLink: 'https://wa.me/+628128236791',
  lineLink: 'https://line.me/ti/p/~smartpolitan',
  kakaoLink: 'https://www.kakaocorp.com/',
  wecatLink: 'weixin://dl/chat?smartpolitan',
  youtubeLink: 'https://youtube.com/channel/UCmHsfdaOvGGfPRKkicgKy6g',
  weiboLink: 'https://weibo.com/login.php',
  enOCYoutube: 'https://youtu.be/gycDjCAULAU',
  enOCZoom: 'https://zoom.us/meeting/register/tJAtde-sqTktGNRCG7AvgE2AXh65h57_XvKe',
  enOCMeet: 'https://meet.google.com',
  enOCTeams:
    'https://teams.microsoft.com/l/meetup-join/19%3ameeting_NjQwNzEzZmQtMDAwYi00NDM5LThkYTQtZTM3MTVkNDg3Y2E0%40thread.v2/0?context=%7b%22Tid%22%3a%228d570faa-3bb4-4202-afd3-4f37a6d99667%22%2c%22Oid%22%3a%22673e40f9-fd8c-4e58-b475-cd2abc0c3099%22%2c%22IsBroadcastMeeting%22%3atrue%7d',
  enOCCisco: 'https://www.cisco.webex.com',
  idOCYoutube: 'https://www.youtube.com/',
  idOCZoom: 'https://zoom.id',
  idOCMeet: 'https://meet.google.com',
  idOCTeams: 'https://www.microsoft.team.com',
  idOCCisco: 'https://www.cisco.webex.com',
  cnOCYoutube: 'https://youtu.be/iGF1CGckFB4',
  cnOCZoom: 'https://us02web.zoom.us/meeting/register/tZIlf-yrpjwsGtXhVAVtZCshEP0qmN-C3bFD',
  cnOCMeet: 'https://meet.google.com',
  cnOCTeams:
    'https://teams.microsoft.com/l/meetup-join/19%3ameeting_M2UzOGI4NWMtYjEzZC00MGE2LTg3ZWQtMzk3M2U0NzY0Y2Y4%40thread.v2/0?context=%7b%22Tid%22%3a%228d570faa-3bb4-4202-afd3-4f37a6d99667%22%2c%22Oid%22%3a%22bba45fbf-1c2e-49fe-8517-81053bb953a7%22%2c%22IsBroadcastMeeting%22%3atrue%7d',
  cnOCCisco: 'https://www.cisco.webex.com',
  jpOCYoutube: 'https://youtu.be/pBtcG91K6Tk',
  jpOCZoom: 'https://zoom.us/meeting/register/tJEsdeuvrD0qHNf5RF34PhLkGSXtMYzqS0WU',
  jpOCMeet: 'https://meet.google.com',
  jpOCTeams:
    'https://teams.microsoft.com/l/meetup-join/19%3ameeting_NmRkMjkwOWItZDNlMi00MmYwLTllMjUtZmZmNDI1NTcyZmY0%40thread.v2/0?context=%7b%22Tid%22%3a%228d570faa-3bb4-4202-afd3-4f37a6d99667%22%2c%22Oid%22%3a%22623fa815-6df9-4110-99d4-81eeeafa3d62%22%2c%22IsBroadcastMeeting%22%3atrue%7d',
  jpOCCisco: 'https://www.cisco.webex.com',
  enMCZoom: 'https://zoom.us/meeting/register/tJAudOytrTgoHNGJ8guRG14RQNcvs8oxvfQF',
};
