// import { Profile } from 'helpers/interfaces/user';

// interface ProfileTypes {
//   GET_PROFILE: 'GET_PROFILE';
//   GET_PROFILE_SUCESS: 'GET_PROFILE_SUCCESS';
//   GET_PROFILE_ERROR: 'GET_PROFILE_ERROR';
//   RESET_GET_PROFILE: 'RESET_GET_PROFILE';
// }

export const profileTypes = {
  GET_PROFILE: 'GET_PROFILE',
  GET_PROFILE_SUCESS: 'GET_PROFILE_SUCCESS',
  GET_PROFILE_ERROR: 'GET_PROFILE_ERROR',
  RESET_GET_PROFILE: 'RESET_GET_PROFILE',
};

// interface GetProfileAction {
//   type: typeof profileTypes.GET_PROFILE;
// }

// interface GetProfileSuccessAction {
//   type: typeof profileTypes.GET_PROFILE_SUCESS;
//   payload: Profile;
// }

// interface GetProfileErrorAction {
//   type: typeof profileTypes.GET_PROFILE_ERROR;
//   payload: Error;
// }

// interface ResetGetProfileAction {
//   type: typeof profileTypes.RESET_GET_PROFILE;
// }

// export type ProfileActionTypes =
//   | GetProfileAction
//   | GetProfileSuccessAction
//   | GetProfileErrorAction
//   | ResetGetProfileAction;
