import { combineReducers } from 'redux';
import Auth from './auth/reducers';
import Notification from './notification/reducers';
import ProfileReducer from './profile/reducers';
import TopbarNotifReducer from './topbarNotif/reducers';

const rootReducer = combineReducers({
  Auth,
  Notification,
  Profile: ProfileReducer,
  TopbarNotif: TopbarNotifReducer,
});

export default rootReducer;

// export type RootState = ReturnType<typeof rootReducer>;
