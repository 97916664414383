import { fetchJSON } from './fetchJSON';
import { getToken } from '../../helpers/authUtils';

export class Api {
  private baseUrl: string;
  private defaultHeader: HeadersInit = {
    'Content-Type': 'application/json',
  };

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  get = (path: string, options: RequestInit = {}) => {
    delete options.method;
    const token = getToken();
    const headers = { ...this.defaultHeader, token, ...options.headers };

    return fetchJSON(this.baseUrl + path, {
      ...options,
      headers,
      method: 'GET',
    });
  };

  post = (path: string, options: RequestInit = {}) => {
    delete options.method;
    const token = getToken();
    const headers = { ...this.defaultHeader, token, ...options.headers };

    return fetchJSON(this.baseUrl + path, {
      ...options,
      headers,
      method: 'POST',
    });
  };

  put = (path: string, options: RequestInit = {}) => {
    delete options.method;
    const token = getToken();
    const headers = { ...this.defaultHeader, token, ...options.headers };

    return fetchJSON(this.baseUrl + path, {
      ...options,
      headers,
      method: 'PUT',
    });
  };

  delete = (path: string, options: RequestInit = {}) => {
    delete options.method;
    const token = getToken();
    const headers = { ...this.defaultHeader, token, ...options.headers };

    return fetchJSON(this.baseUrl + path, {
      ...options,
      headers,
      method: 'DELETE',
    });
  };

  patch = (path: string, options: RequestInit = {}) => {
    delete options.method;
    const token = getToken();
    const headers = { ...this.defaultHeader, token, ...options.headers };

    return fetchJSON(this.baseUrl + path, {
      ...options,
      headers,
      method: 'PATCH',
    });
  };

  plainGet = (path: string, options: RequestInit = {}) => {
    delete options.method;
    const headers = { ...this.defaultHeader, ...options.headers };

    return fetchJSON(this.baseUrl + path, {
      ...options,
      headers,
      method: 'GET',
    });
  };

  plainPost = (path: string, options: RequestInit = {}) => {
    delete options.method;
    const headers = { ...this.defaultHeader, ...options.headers };

    return fetchJSON(this.baseUrl + path, {
      ...options,
      headers,
      method: 'POST',
    });
  };
}
