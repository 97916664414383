import React, { Suspense } from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';

import { flattenedStandardLayoutRoutes, flattenedCleanLayoutRoutes, flattenedStandardV2LayoutRoutes } from './index';

// const FullPageLayout = React.lazy(() => import('../layouts/FullPageLayout'));
// const DashboardLayout = React.lazy(() => import('../layouts/DashboardLayout'));
const StandardLayout = React.lazy(() => import('../layouts/StandardLayout'));
const StandardV2Layout = React.lazy(() => import('../layouts/StandardV2Layout'));
const CleanLayout = React.lazy(() => import('../layouts/CleanLayout'));

const Routes = () => {
  return (
    <BrowserRouter>
      <Switch>
        {flattenedStandardLayoutRoutes.map((route, index) => {
          return (
            <route.route
              key={index}
              path={route.path}
              exact={route.exact}
              component={(props) => (
                <Suspense fallback={null}>
                  <StandardLayout>
                    <route.component {...props} />
                  </StandardLayout>
                </Suspense>
              )}
            />
          );
        })}
      </Switch>
      <Switch>
        {flattenedStandardV2LayoutRoutes.map((route, index) => {
          return (
            <route.route
              key={index}
              path={route.path}
              exact={route.exact}
              component={(props) => (
                <Suspense fallback={null}>
                  <StandardV2Layout>
                    <route.component {...props} />
                  </StandardV2Layout>
                </Suspense>
              )}
            />
          );
        })}
      </Switch>
      <Switch>
        {flattenedCleanLayoutRoutes.map((route, index) => {
          return (
            <route.route
              key={index}
              path={route.path}
              exact={route.exact}
              component={(props) => (
                <Suspense fallback={null}>
                  <CleanLayout>
                    <route.component {...props} />
                  </CleanLayout>
                </Suspense>
              )}
            />
          );
        })}
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
