import jwtDecode from 'jwt-decode';
import cookies from 'js-cookie';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';

interface TokenDto {
  foo: string;
  exp: number;
  iat: number;
}

export const isUserAuthenticated = () => {
  const token: string = getToken();
  if (isEmpty(token)) {
    return false;
  }

  const tokenExpiringTimestamps = jwtDecode<TokenDto>(token).exp;
  const tokenExpiringTime = dayjs.unix(tokenExpiringTimestamps);
  const currentTime = dayjs();

  if (tokenExpiringTime < currentTime) {
    cookies.remove('user');
    return false;
  }

  return true;
};

interface User {
  id: string;
  nip: string;
  name: string;
  role: string;
  token: string;
}

export const getLoggedInUser = (): User => {
  const user = cookies.get('user');
  return isEmpty(user) ? {} : JSON.parse(user as string);
};

export const getToken = (): string => {
  const user = getLoggedInUser();

  return user ? user.token : '';
};
