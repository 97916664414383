import { profileTypes } from './types';

export const getProfile = () => ({
  type: profileTypes.GET_PROFILE,
});

export const getProfileSuccess = (payload) => ({
  type: profileTypes.GET_PROFILE_SUCESS,
  payload,
});

export const getProfileError = (error) => ({
  type: profileTypes.GET_PROFILE_ERROR,
  payload: error,
});

export const resetGetProfile = () => ({
  type: profileTypes.RESET_GET_PROFILE,
});
