import {
  topbarNotifTypes,
} from './types';

const initialState = {
  isLoaded: false,
  topbarNotif: [],
};

export default function topbarNotifReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case topbarNotifTypes.ADD_TOPBAR_NOTIF:
      return { ...state, topbarNotif: [...state.topbarNotif, action.payload] };
    case topbarNotifTypes.ADD_MULTI_TOPBAR_NOTIF:
      const newData = [];
      action.payload.forEach((v) => {
        if (
          !(
            state.topbarNotif.filter((element) => {
              return element.id === v.id;
            }).length > 0
          )
        ) {
          newData.push({
            id: v.id,
            icon: 'uil uil-comment-message',
            bgColor: 'danger',
            text: v.text,
            subText: v.subText,
          });
        }
      });
      return { ...state, topbarNotif: [...state.topbarNotif, ...newData] };
    case topbarNotifTypes.SET_TOPBAR_NOTIF_LOADED:
      return { ...state, isLoaded: action.payload };
    case topbarNotifTypes.GET_TOPBAR_NOTIF:
      return state;
    default:
      return state;
  }
}
