/* eslint-disable react/display-name */

import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import * as FeatherIcon from 'react-feather';

import { isUserAuthenticated } from '../helpers/authUtils';

const Dashboard = React.lazy(() => import('../pages/dashboard'));
const Page = React.lazy(() => import('../pages/page'));
const Home = React.lazy(() => import('../pages/home'));
const AboutUs = React.lazy(() => import('../pages/aboutUs'));
const ContactUs = React.lazy(() => import('../pages/contactUs'));
const UtilitiesElectricity = React.lazy(() => import('../pages/utilities/electricity'));
const OnboardingDetail = React.lazy(() => import('../pages/onboardingDetail'));
const EventDetail = React.lazy(() => import('../pages/eventDetail'));
const HomeV2 = React.lazy(() => import('../pages/v2/home'));
const PrivacyPolicyV2 = React.lazy(() => import('../pages/v2/privacy-policy'));
const DisclaimerV2 = React.lazy(() => import('../pages/v2/disclaimer'));

// Handle auth and authorization
const PrivateRoute = ({ component: Component, path, exact }) => (
  <Route
    path={path}
    exact={exact}
    render={(props) => {
      if (isUserAuthenticated()) {
        // if authorized
        return <Component {...props} />;
      }

      // Redirect to login page if not authenticated
      return <Redirect to={{ pathname: '/account/login' }} />;
    }}
  />
);

// Root routing
const rootRoute = {
  name: 'Root',
  path: '/',
  exact: true,
  disableFromMenu: true,
  component: () => <Redirect to="/home" />,
  route: Route,
};

// Dashboard routing
const dashboardRoutes = {
  path: '/dashboard',
  name: 'Dashboard',
  icon: FeatherIcon.Home,
  header: 'Menu',
  component: Dashboard,
  route: PrivateRoute,
  exact: true,
};

// Page routing
const pageRoutes = {
  path: '/page/:id',
  name: 'Page',
  icon: FeatherIcon.Home,
  header: 'Menu',
  component: Page,
  route: Route,
  exact: true,
};

const homeRoutes = {
  path: ['/company/:id', '/onlinersvp', '/groundbreakingrsvp/:id', '/registration'],
  name: 'Home',
  icon: FeatherIcon.Home,
  header: 'Menu',
  component: Home,
  route: Route,
  exact: true,
};

const aboutUsRoutes = {
  path: '/about-us',
  name: 'AboutUs',
  icon: FeatherIcon.Home,
  header: 'AboutUs',
  component: AboutUs,
  route: Route,
  exact: true,
};

const contactUsRoutes = {
  path: '/contact-us',
  name: 'ContactUs',
  icon: FeatherIcon.Home,
  header: 'ContactUs',
  component: ContactUs,
  route: Route,
  exact: true,
};

const eventDetailRoutes = {
  path: '/event-detail',
  name: 'EventDetail',
  icon: FeatherIcon.Home,
  header: 'Event Detail',
  component: EventDetail,
  route: Route,
  exact: true,
}

const utilitiesElectricityRoutes = {
  path: '/utilities/electricity',
  name: 'UtilitiesElectricity',
  icon: FeatherIcon.Home,
  header: 'UtilitiesElectricity',
  component: UtilitiesElectricity,
  route: Route,
  exact: true,
};

const onboardingDetailRoutes = {
  path: '/onboarding-detail',
  name: 'OnboardingDetail',
  icon: FeatherIcon.Home,
  header: 'Onboarding Detail',
  component: OnboardingDetail,
  route: Route,
  exact: true,
}

const homeV2Routes = {
  path: '/home',
  name: 'HomeV2',
  icon: FeatherIcon.Home,
  header: 'Home',
  component: HomeV2,
  route: Route,
  exact: true,
}

const privacyPolicyV2Routes = {
  path: '/privacy-policy',
  name: 'PrivacyPolicyV2',
  icon: FeatherIcon.Home,
  header: 'Privacy Policy',
  component: PrivacyPolicyV2,
  route: Route,
  exact: true,
}

const disclaimerV2Routes = {
  path: '/disclaimer',
  name: 'DisclaimerV2',
  icon: FeatherIcon.Home,
  header: 'Disclaimer',
  component: DisclaimerV2,
  route: Route,
  exact: true,
}

// Authentication routing
// const authRoutes = {
//   path: '/account',
//   name: 'Auth',
//   component: () => <div>Authentication</div>,
//   route: Route,
//   exact: true,
//   children: [
//     {
//       path: '/account/login',
//       name: 'Login',
//       component: Login,
//       route: Route,
//       exact: false,
//     },
//     {
//       path: '/account/logout',
//       name: 'Logout',
//       component: Logout,
//       route: Route,
//       exact: false,
//     },
//   ],
// };

// Flatten the list of all nested routes
const flattenRoutes = (routes) => {
  let flatRoutes = [];

  routes = routes || [];
  routes.forEach((item) => {
    flatRoutes.push(item);

    if (typeof item.children !== 'undefined') {
      flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
    }
  });
  return flatRoutes;
};

const cleanLayoutRoutes = [
  onboardingDetailRoutes
];

const standardV2LayoutRoutes = [
  homeV2Routes,
  privacyPolicyV2Routes,
  disclaimerV2Routes,
]

const standardLayoutRoutes = [
  rootRoute,
  dashboardRoutes,
  pageRoutes,
  homeRoutes,
  aboutUsRoutes,
  contactUsRoutes,
  utilitiesElectricityRoutes,
  eventDetailRoutes
];

// const flattenedAuthRoutes = flattenRoutes([authRoutes]);
const flattenedStandardLayoutRoutes = flattenRoutes(standardLayoutRoutes);
const flattenedCleanLayoutRoutes = flattenRoutes(cleanLayoutRoutes);
const flattenedStandardV2LayoutRoutes = flattenRoutes(standardV2LayoutRoutes);

export { cleanLayoutRoutes, standardLayoutRoutes, standardV2LayoutRoutes, flattenedCleanLayoutRoutes, flattenedStandardLayoutRoutes, flattenedStandardV2LayoutRoutes };
