import { SHOW_NOTIFICATION, HIDE_NOTIFICATION } from './constants';

const INIT_STATE = {
  show: false,
  timeout: 4000,
  color: 'primary',
  message: '',
};

const notification = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SHOW_NOTIFICATION:
      return {
        ...state,
        show: true,
        timeout: action.payload.timeout || 4000,
        color: action.payload.color || 'primary',
        message: action.payload.message,
      };
    case HIDE_NOTIFICATION:
      return INIT_STATE;
    default:
      return state;
  }
};

export default notification;
